import "./../styles/footer.scss";
import { FaDiscord, FaInstagram, FaTwitter } from "react-icons/fa";
import MusicToggle from "./MusicToggle.tsx";
import { MutableRefObject } from "react";

export default function Footer({
	player,
}: { player: MutableRefObject<HTMLAudioElement> }) {
	return (
		<footer>
			<div className="spacing" />

			<div className="social">
				<a target="_blank" href="https://twitter.com/AmorxMundi">
					<FaTwitter />
				</a>
				<a target="_blank" href="https://discord.gg/6rE9ssSrXC">
					<FaDiscord />
				</a>
				{/*<a target="_blank" href="https://www.instagram.com/amorxmundi/">*/}
				{/*	<FaInstagram />*/}
				{/*</a>*/}
			</div>

			<MusicToggle player={player} />
		</footer>
	);
}
