import "./../styles/music-toggle.scss";
import { FiVolume2, FiVolumeX } from "react-icons/fi";
import { MutableRefObject, useEffect, useRef, useState } from "react";

export default function MusicToggle({
	player,
}: { player: MutableRefObject<HTMLAudioElement> }) {
	const [started, setStarted] = useState(false);
	const [muted, setMuted] = useState(true);
	const [volume, setVolume] = useState(1);

	if (player.current != null) {
		player.current.muted = muted;
		player.current.volume = volume;
	}

	const listener = () => {
		if (!started) {
			player.current.play();
			setStarted(true);
			setMuted(false);
			window.removeEventListener("click", listener);
		}
	};

	useEffect(() => {
		if (!started) window.addEventListener("click", listener);
		else window.removeEventListener("click", listener);
	});

	if (started) window.removeEventListener("click", listener);

	return (
		<>
			<div className={`music-toggle ${muted ? "muted" : ""}`}>
				<FiVolume2
					style={{ display: muted ? "none" : "" }}
					onClick={() => {
						if (!started) {
							player.current.play();
							setStarted(true);
						}
						setMuted(!muted);
					}}
				/>
				<FiVolumeX
					style={{ display: muted ? "" : "none" }}
					onClick={() => {
						if (!started) {
							player.current.play();
							setStarted(true);
						}
						setMuted(!muted);
					}}
				/>

				<div className="slider">
					<input
						type="range"
						min="1"
						max="100"
						defaultValue="100"
						className="slider"
						id="myRange"
						onChange={(e) => {
							setVolume(e.target.value / 100);
						}}
					/>
				</div>
			</div>
		</>
	);
}
