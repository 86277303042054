import Header from "../components/Header.tsx";
import Footer from "../components/Footer.tsx";
import Hashids from "hashids";
import "./../styles/index.scss";
import { useRef, useState } from "react";
import TypeWriterEffect from "react-typewriter-effect";

const hashids = new Hashids();

export default function Index() {
	const id = hashids.encode(Math.floor(Math.random() * 999999999999999));
	const text = `This is proof of my attendance in\n@amorxmundi experience\n\nMy ID: ${id}\n\nΣαrly ōnεs ωill bε rεwardεd\n#AmorMundi\nhttps://amormundi.io/`;
	const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
		text,
	)
		.replace(/'/g, "%27")
		.replace(/"/g, "%22")}`;

	const player = useRef(null);

	const [verified, setVerified] = useState(false);
	const [joined, setJoined] = useState(false);

	return (
		<div className="background">
			<audio ref={player} id="player" loop style={{ display: "none" }}>
				<source src="/assets/audio.mp3" type="audio/mp3" />
			</audio>

			<video
				className="background-video"
				autoPlay
				controls={false}
				loop
				muted
				playsInline
				style={{ pointerEvents: "none" }}
			>
				<source src="/assets/seq-cloud.mp4" type="video/mp4" />
			</video>

			<Header />

			<div className="content">
				<div className="leading">
					{/*<span>A Sublime Experience</span>*/}
					{/*<span>An Interactive Art Video Game</span>*/}
					{/*<span>Uniting Physical And Virtual Worlds</span>*/}
					{/*<span>Discover New Worlds, Enter Secret Spaces</span>*/}
					{/*<span>Expand Your Knowledge And Consciousness</span>*/}
				</div>
				<div
					className={`verify ${verified ? "verified" : ""} ${
						joined ? "joined" : ""
					}`}
				>
					<a
						href={
							verified
								? joined
									? null
									: "https://discord.gg/6rE9ssSrXC"
								: tweetUrl
						}
						className="animated-button"
						target="_blank"
						onClick={() => {
							setTimeout(() => {
								if (!verified) setVerified(true);
								else setJoined(true);
							}, 500);
						}}
					>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<div className="invisible">
							{verified
								? joined
									? "Next Steps coming soon!-"
									: "Join Our Community-"
								: "Start Your Journey-"}
						</div>
						<div>
							<div
								style={{
									display: joined ? "block" : "none",
								}}
							>
								<TypeWriterEffect
									textStyle={{
										fontSize: "1rem",
										letterSpacing: ".1rem",
									}}
									multiTextDelay={3000}
									multiTextLoop
									multiText={["Next Steps coming soon!"]}
									cursorColor="white"
								/>
							</div>

							<div
								style={{
									display: verified && !joined ? "block" : "none",
								}}
							>
								<TypeWriterEffect
									textStyle={{
										fontSize: "1rem",
										letterSpacing: ".1rem",
									}}
									multiTextDelay={3000}
									multiTextLoop
									multiText={["Join Our Community"]}
									cursorColor="white"
								/>
							</div>

							<div
								style={{
									display: verified ? "none" : "block",
								}}
							>
								<TypeWriterEffect
									textStyle={{
										fontSize: "1rem",
										letterSpacing: ".1rem",
									}}
									multiTextDelay={3000}
									multiTextLoop
									multiText={["Start Your Journey"]}
									cursorColor="white"
								/>
							</div>
						</div>
					</a>
				</div>
				<div className="spacing" />
			</div>

			<Footer player={player} />
		</div>
	);
}
