import "./../styles/header.scss";
import { Player } from "@lottiefiles/react-lottie-player";

export default function Header() {
	return (
		<nav>
			<Player autoplay loop src="/assets/logo.json" className="logo" />
			{/*<img src="/assets/logo.svg" alt="Amor Mundi" className="logo" />*/}
			<div className="brand">Amor Mundi</div>
			<div className="logo spacing" />
		</nav>
	);
}
